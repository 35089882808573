import React from "react"
import * as R from "ramda"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Img from "gatsby-image"
import Link from "gatsby-link"
import "./releaseItem.scss"
import { useIntl } from "gatsby-plugin-intl"
import { IRelease } from "../types/models/release"
import { IArtist } from "../types/models/artist"
const isBrowser = typeof window !== "undefined"

export default ({ data }: { data?: IRelease }) => {
  const intl = useIntl()
  if (!data) {
    return null
  }
  const imageSize = 240
  const detailLink = `/${intl.locale}/releases/${data.slug}`
  const imageUrl = `${data.cover.url}?x-oss-process=image/resize,w_${
    isBrowser ? window.devicePixelRatio * imageSize : imageSize
  },h_${isBrowser ? window.devicePixelRatio * imageSize : imageSize}`
  const serialAndType = `${data.serial_number} / ${data.release_type}`

  const artistNames = R.pipe(
    R.pathOr([], ["artists"]),
    R.map((artist: IArtist) => (
      <Link
        key={artist.slug + Date.now()}
        to={`/${intl.locale}/artists/${artist.slug}`}
        className="artist-link"
      >
        {artist.name}
      </Link>
    )),
    R.ifElse(
      R.gt(R.length(R.__, 2)),
      R.pipe(
        R.intersperse(intl.locale === "en" ? <span>, </span> : <span>，</span>),
        R.update(-2, <span> & </span>)
      ),
      R.intersperse(<span> & </span>)
    )
  )(data)

  const title = data.name
  return (
    <section className="grid-release-item">
      <span className="type ABC-Mono">{serialAndType}</span>
      <Link to={detailLink} className="link">
        <Img
          fixed={{
            src: imageUrl,
            srcSet: imageUrl,
            width: 240,
            height: 240,
          }}
          alt={data.name}
          title={data.slug}
          backgroundColor="#000"
        />
      </Link>
      <div className="artists ABC">{artistNames.length ? artistNames : ""}</div>
      <div className="title ABC-Bold">
        <Link to={detailLink}>{title}</Link>
      </div>
    </section>
  )
}
